<template>
  <div>
    <v-card flat>
      <v-tabs vertical class="my-tabs">
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Barriers List
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Failures List
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Tubulars
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Tests frequency
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Components
        </v-tab>

        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Fluids
        </v-tab>

        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Formations
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Fields
        </v-tab>

        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Well's Status
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Well's Types
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Companys
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Corrosion Data
        </v-tab>
        <v-tab>
          <v-icon left> mdi-weight-kilogram </v-icon>Unit of mesure
        </v-tab>
        <v-tab>
          <v-icon left> mdi-file-key-outline </v-icon> Codes Documents
        </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Options
        </v-tab>
        <v-tab> <v-icon left> mdi-flag-checkered </v-icon>Flags </v-tab>
        <v-tab>
          <v-icon left> mdi-calendar-filter-outline </v-icon>Events journal
        </v-tab>
        <v-tab>
          <v-icon left> mdi-bell-alert-outline </v-icon>Notifications
        </v-tab>
        <v-tab> <v-icon left> mdi-file-tree </v-icon>WF & Tasks </v-tab>
        <!-- Barriers List -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Barriers List'"
                  :list="barriers_list"
                  :qDelete="Qbarrierdelete"
                  :qCreate="Qbarriercreate"
                  :qUpdate="Qbarrierupdate"
                  :headers="barrierheaders"
                  :add="true"
                  :del="true"
                  :showedit="false"
                  :master="true"
                  :key="kbarrier"
                  @open="BarrierOpen"
                  @rowselect="RowBarrierSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Failures List -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Failures List'"
                  :list="failures_list"
                  :qDelete="Qfailuredelete"
                  :qCreate="Qfailurecreate"
                  :qUpdate="Qfailureupdate"
                  :headers="failureheaders"
                  :add="true"
                  :del="true"
                  :showstd="true"
                  :Get_suf="'Failure'"
                  :master="true"
                  :key="Kfailure"
                  :Update="true"
                  :laoding="Loading"
                  @rowselect="RowFailureSelect"
                  @change="RowFailureChange"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tubulars -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Tubulars'"
                  :list="tubulars_list"
                  :qDelete="Qtubulardelete"
                  :qCreate="Qtubularcreate"
                  :qUpdate="Qtubularupdate"
                  :headers="tubularheaders"
                  :add="false"
                  :del="false"
                  :showstd="true"
                  :Get_suf="'Tubular'"
                  :master="false"
                  :key="Ktubular"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :laoding="Loading"
                  @rowselect="RowTubularSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Well Type Components ----------------------------------->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Tests Frequency'"
                  :headers="twt_headers"
                  :list="testwelltype_alllist"
                  :add="false"
                  :del="false"
                  :showedit="true"
                  :Get_suf="'WellTypeComponent'"
                  :master="true"
                  :laoding="Loading"
                  :key="Kwelltypecmp"
                  @col_btn1_click="TestWellTypeOpen"
                  @rowselect="RowWTCSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Components -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <listitems
                  :title="'Component type'"
                  :list="components_type_list"
                  :qDelete="Qcomponent_typedelete"
                  :qCreate="Qcomponent_typecreate"
                  :qUpdate="Qcomponent_typeupdate"
                  :headers="component_type_headers"
                  :showstd="true"
                  :Get_suf="'ComponentType'"
                  :master="false"
                  :key="Kcomponent_type"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :del_disable="Component_type.cnt1 > 0"
                  @delete="Kcomponent_type++"
                  :laoding="Loading"
                  @rowselect="RowComponent_Type_Select"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-tabs v-model="tabs" align-tabs="right">
                  <v-tab key="1" href="#1"> Components </v-tab>
                  <v-tab key="3" href="#2"> Failures </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                  <v-tab-item :value="'1'">
                    <listitems
                      :list="components_list"
                      :qDelete="Qcomponentdelete"
                      :qCreate="Qcomponentcreate"
                      :qUpdate="Qcomponentupdate"
                      :headers="componentheaders"
                      :showstd="false"
                      :showedit="false"
                      :master="false"
                      :Get_suf="'Component'"
                      :hdf="true"
                      :key="Kcomponent"
                      :add="false"
                      :del_disable="Component.cnt > 0"
                      @delete="Kcomponent++"
                      :laoding="Loading"
                      @col_btn1_click="ComponentOpen"
                      @rowselect="RowComponentSelect"
                    >
                    </listitems>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <listitems
                      :list="failures_type_list"
                      :qDelete="Qfailure_cmp_typedelete"
                      :qCreate="Qfailure_cmp_typecreate"
                      :qUpdate="Qfailure_cmp_typeupdate"
                      :headers="failuretypeheaders"
                      :showstd="true"
                      :Get_suf="'Failure_Cmp_Type'"
                      :master="false"
                      :key="Kfailuretype"
                      :add="
                        (failuretypeheaders[1].default
                          ? failuretypeheaders[1].default
                          : -1) > 0
                      "
                      :Update="true"
                      :search="true"
                      :laoding="Loading"
                      @rowselect="RowFailure_cmp_typeSelect"
                      @add="RowFailure_cmp_typeAdd"
                      @change="RowFailure_cmp_typeChange"
                    >
                    </listitems>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Fluids -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <listitems
                  :title="'Fluids'"
                  :list="fluids_list"
                  :qDelete="Qfluiddelete"
                  :qCreate="Qfluidcreate"
                  :qUpdate="Qfluidupdate"
                  :headers="fluidheaders"
                  :showstd="true"
                  :Get_suf="'Fluid'"
                  :master="false"
                  :key="Kfluid"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :del_disable="Fluid.cnt > 0"
                  @delete="Kfluid++"
                  :laoding="Loading"
                  @rowselect="RowFluidSelect"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <listitems
                  :title="'Fluid Elements'"
                  :list="elements_list"
                  :qDelete="Qelementdelete"
                  :qCreate="Qelementcreate"
                  :qUpdate="Qelementupdate"
                  :headers="elementheaders"
                  :showstd="true"
                  :Get_suf="'Element'"
                  :master="false"
                  :key="Kelement"
                  :add="Fluid.id > 0"
                  :Update="true"
                  :search="true"
                  :del_disable="element.elementcount > 0"
                  :laoding="Loading"
                  @rowselect="RowElementSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Formations -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Formations'"
                  :list="formations_list"
                  :qDelete="Qformationdelete"
                  :qCreate="Qformationcreate"
                  :qUpdate="Qformationupdate"
                  :headers="formationheaders"
                  :Get_suf="'Formation'"
                  :showstd="true"
                  :master="true"
                  :key="Kformation"
                  :add="true"
                  :Update="true"
                  :del_disable="Formation.cnt > 0"
                  @delete="Kformation++"
                  @rowselect="RowFormationSelect"
                  @change="RowFormationChange"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Fields -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <listitems
                  :title="'Fields'"
                  :list="active_fields"
                  :qDelete="Qfielddelete"
                  :qCreate="Qfieldcreate"
                  :qUpdate="Qfieldupdate"
                  :headers="fieldheaders"
                  :showstd="true"
                  :Get_suf="'Field'"
                  :master="false"
                  :key="Kfield"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :del_disable="
                    Field.wellcount > 0 ||
                    Field.formationcount > 0 ||
                    Field.childcount > 0
                  "
                  @delete="Kfield++"
                  :laoding="Loading"
                  @rowselect="RowFieldSelect"
                >
                </listitems>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <listitems
                  :title="'Formations'"
                  :list="fieldsformations_list"
                  :qDelete="Qfieldformationdelete"
                  :headers="fieldformationheaders"
                  :showstd="true"
                  :Get_suf="'FormationField'"
                  :master="false"
                  :key="Kfieldformation"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :push="true"
                  :insert="false"
                  :add_disable="Field.id != null"
                  :del_disable="
                    FieldFormation.wellcount > 0 ||
                    FieldFormation.fluidcount > 0
                  "
                  :laoding="Loading"
                  @rowselect="RowFieldFormationSelect"
                  @add="field_formation_add"
                  @delete="field_formation_delete"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="4" md="4" v-if="FieldFormation.id > 0">
                <listitems
                  :title="'Fluids'"
                  :list="fieldsformations_fluids"
                  :qCreate="Qfieldformationfluidcreate"
                  :qUpdate="Qfieldformationfluidupdate"
                  :qDelete="Qfieldformationfluiddelete"
                  :headers="fieldformation_fluids_headers"
                  :showstd="true"
                  :Get_suf="'FormationFieldFluid'"
                  :master="false"
                  :key="Kfieldformationfluid"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :push="true"
                  :add_disable="FieldFormation.id != null"
                  :del_disable="
                    FieldFormationFluid.refrences
                      ? FieldFormationFluid.refrences.length > 0
                      : false
                  "
                  :laoding="Loading"
                  @rowselect="RowFieldFormationFluidSelect"
                  @add="field_formation_fluid_add"
                  @delete="field_formation_fluid_delete"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="8" md="8" v-if="FieldFormationFluid.id > 0">
                <listitems
                  :title="'References'"
                  :list="fieldsformations_fluids_references"
                  :headers="fieldformation_fluids_refrences_headers"
                  :showstd="false"
                  :showedit="false"
                  :master="false"
                  :key="Kfieldformationfluidref"
                  :add="true"
                  :Update="true"
                  :search="true"
                  :push="true"
                  :add_disable="FieldFormationFluid.id != null"
                  :laoding="Loading"
                  @open="TestOpen"
                  @col_btn1_click="TestOpen"
                  @rowselect="RowFieldFormationFluidRefSelect"
                  @add="field_formation_fluid_add"
                  @delete="field_formation_fluid_delete"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Well's status -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Wells status'"
                  :list="wellsstatus"
                  :qDelete="Qwstatusdelete"
                  :qCreate="Qwstatuscreate"
                  :qUpdate="Qwstatusupdate"
                  :headers="wstatusheaders"
                  :showstd="true"
                  :Get_suf="'Wellstatus'"
                  :master="false"
                  :key="Kwstatus"
                  :add="true"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :del="true"
                  :del_disable="WellStatus.cnt > 0"
                  @delete="Kwstatus++"
                  :laoding="Loading"
                  @rowselect="RowWStatusSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Well's types -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Wells types'"
                  :list="wellstypes"
                  :qDelete="Qwtypesdelete"
                  :qCreate="Qwtypescreate"
                  :qUpdate="Qwtypesupdate"
                  :headers="wtypesheaders"
                  :showstd="true"
                  :Get_suf="'Welltype'"
                  :master="false"
                  :key="Kwtypes"
                  :add="true"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :del="true"
                  :del_disable="WellType.cnt > 0"
                  @delete="Kwtypes++"
                  :laoding="Loading"
                  @rowselect="RowWTypesSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Companys -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Companys'"
                  :list="company_list"
                  :qDelete="Qcompanydelete"
                  :qCreate="Qcompanycreate"
                  :qUpdate="Qcompanyupdate"
                  :headers="companyheaders"
                  :Get_suf="'Company'"
                  :showstd="true"
                  :master="true"
                  :key="kcompany"
                  :add="true"
                  :Update="true"
                  @rowselect="RowCompanySelect"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!--Corrosion Data--->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <listitems
                  :title="'Tools'"
                  :list="tool_list"
                  :qDelete="Qtooldelete"
                  :qCreate="Qtoolcreate"
                  :qUpdate="Qtoolupdate"
                  :headers="toolheaders"
                  :showstd="true"
                  :Get_suf="'Tool'"
                  :master="false"
                  :key="ktool"
                  :add="true"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :del="true"
                  :del_disable="toolsdetails > 0"
                  @delete="ktool++"
                  :laoding="Loading"
                  @rowselect="RowToolSelect"
                >
                </listitems>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <listitems
                  :title="'Details'"
                  :list="tool_detail_list"
                  :qDelete="Qtool_detaildelete"
                  :qCreate="Qtool_detailcreate"
                  :qUpdate="Qtool_detailupdate"
                  :headers="tool_detailheaders"
                  :showstd="true"
                  :Get_suf="'Tooldetail'"
                  :master="false"
                  :key="ktool_detail"
                  :add="true"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :del="true"
                  @delete="Ktool_detail++"
                  :laoding="Loading"
                  @rowselect="RowTool_detailSelect"
                >
                </listitems>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <listitems
                  :title="'Levels'"
                  :list="level_list"
                  :qDelete="Qleveldelete"
                  :qCreate="Qlevelcreate"
                  :qUpdate="Qlevelupdate"
                  :headers="levelheaders"
                  :showstd="true"
                  :Get_suf="'Level'"
                  :master="false"
                  :key="klevel"
                  :add="true"
                  :hdf="true"
                  :Update="true"
                  :search="true"
                  :del="true"
                  @delete="klevel++"
                  :laoding="Loading"
                  @rowselect="RowLevelSelect"
                >
                </listitems>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <listitems
                  :title="'Defaults'"
                  :list="defaults_list"
                  :qDelete="Qdefaultdelete"
                  :qCreate="Qdefaultcreate"
                  :qUpdate="Qdefaultupdate"
                  :headers="defaultheaders"
                  :showstd="true"
                  :Get_suf="'Default'"
                  :master="false"
                  :key="kdefault"
                  :add="true"
                  :hdf="true"
                  :Update="cor_default.id != 1"
                  :search="true"
                  :del="true"
                  :del_disable="cor_default.id == 1"
                  @delete="kdefault++"
                  :laoding="Loading"
                  @rowselect="RowdDefaultSelect"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Unites de Mesure -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Units Categories'"
                  :list="unitcategories"
                  :qDelete="Qucdelete"
                  :qCreate="Quccreate"
                  :qUpdate="Qucupdate"
                  :headers="unitcatheaders"
                  :Get_suf="'UnitCategory'"
                  :master="false"
                  :key="kuc"
                  :add="false"
                  :Update="false"
                  :del="false"
                  @rowselect="OpenUnitCatForm"
                  :del_disable="unit_list.length > 0"
                  @delete="kuc++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Units'"
                  :list="unit_list"
                  :qDelete="Qunitdelete"
                  :qCreate="Qunitcreate"
                  :qUpdate="Qunitupdate"
                  :Get_suf="'Unit'"
                  :showstd="true"
                  :headers="unitsheaders"
                  :master="true"
                  :key="ku"
                  :add="true"
                  :Update="true"
                  :add_disable="uc.id != null"
                  :del="false"
                  @rowselect="OpenUnitForm"
                  @delete="ku++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Tab Codes Documents -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="docs_types"
                :title="'Code Document'"
                :qUpdate="Qdoctypeupdate"
                :headers="docs_typesheaders"
                :Get_suf="'Document'"
                :master="true"
                :key="kdoctype"
                :add="false"
                :Update="true"
                :del="false"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Options -->
        <v-tab-item>
          <v-card flat>
            <v-toolbar>
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
              <v-toolbar-title>Options</v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                @click="save_options"
                :disabled="change == 1"
              >
                <v-icon left> mdi-content-save </v-icon>
                Save
              </v-btn>
            </v-toolbar>
            <v-progress-linear
              indeterminate
              color="purple"
              height="8"
              v-if="progress"
            ></v-progress-linear>
            <v-card-text>
              <v-row class="mt-8">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    v-model.number="options.doc_top"
                    label="Top Margin"
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    v-model.number="options.doc_bottom"
                    label="Bottom Margin"
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    v-model.number="options.doc_left"
                    label="Left Margin"
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    v-model.number="options.doc_right"
                    label="Right Margin"
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    :items="volume_units"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Volume unit required']"
                    v-model="options.volume_id"
                    label="Volume unit"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    :items="pressure_units"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Pressure unit required']"
                    v-model="options.pressure_id"
                    label="Pressure unit"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    :items="weight_units"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Weight unit required']"
                    v-model="options.weight_id"
                    label="Weight unit"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    :items="depth_units"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Depth unit required']"
                    v-model="options.depth_id"
                    label="Depth unit"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    :items="time_units"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Time unit required']"
                    v-model="options.time_id"
                    label="Time unit"
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="options.devise"
                    label="Currency "
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    v-model.number="options.max_file_size"
                    label="Taille Max des fichiers(Mo) "
                    dense
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Tab Flags -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Flags'"
                  :list="flags"
                  :qDelete="Qflagdelete"
                  :qCreate="Qflagcreate"
                  :qUpdate="Qflagupdate"
                  :headers="flagheaders"
                  :Get_suf="'Flag'"
                  :showstd="true"
                  :master="true"
                  :key="kflag"
                  :add="true"
                  :Update="true"
                  @delete="kflag++"
                  @rowselect="RowFlagSelect"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Events Keys -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Events'"
                  :list="events"
                  :headers="eventheaders"
                  :showstd="true"
                  :master="true"
                  :add="false"
                  :del="false"
                  :Update="false"
                  @rowselect="RowEventSelect"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Keys'"
                  :list="event_keys"
                  :qDelete="Qeventkeydelete"
                  :qCreate="Qeventkeycreate"
                  :qUpdate="Qeventkeyupdate"
                  :headers="eventkeyheaders"
                  :Get_suf="'Eventkey'"
                  :showstd="true"
                  :master="true"
                  :key="kevent"
                  :add="true"
                  :add_disable="event.id != null"
                  :Update="true"
                  @delete="kevent++"
                  @add="keyAdd"
                  @change="keyChange"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Journals'"
                  :list="journals"
                  :qDelete="Qjournaldelete"
                  :qCreate="Qjournalcreate"
                  :qUpdate="Qjournalupdate"
                  :headers="journalheaders"
                  :Get_suf="'Journal'"
                  :showstd="true"
                  :master="true"
                  :key="kjournal"
                  :add="true"
                  :del_disable="journal.event_count > 0"
                  :Update="true"
                  @rowselect="RowJournaltSelect"
                  @delete="kjournal++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Notifications -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Notifications'"
                  :list="notifs"
                  :qCreate="Qnotifcreate"
                  :qUpdate="Qnotifupdate"
                  :headers="notif_headers"
                  :Get_suf="'NotificationType'"
                  :showstd="true"
                  :master="true"
                  :add="true"
                  :del="false"
                  :Update="true"
                  :key="knotif"
                  @rowselect="RowNotifSelect"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Rules'"
                  :list="notif_rules"
                  :qDelete="Qruledelete"
                  :qCreate="Qrulecreate"
                  :qUpdate="Qruleupdate"
                  :headers="notif_rules_headers"
                  :Get_suf="'NotificationRule'"
                  :showstd="true"
                  :master="false"
                  :key="krule"
                  :add="true"
                  :add_disable="notif.id != null"
                  :del_disable="notif_rule.recipient_count > 0"
                  :Update="true"
                  @delete="krule++"
                  @rowselect="RowRuletSelect"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Recipient'"
                  :list="recipients"
                  :qDelete="Qrecipientdelete"
                  :qCreate="Qrecipientcreate"
                  :qUpdate="Qrecipientupdate"
                  :headers="recipient_headers"
                  :Get_suf="'NotificationRecipient'"
                  :showstd="false"
                  :showedit="false"
                  :master="false"
                  :key="krecip"
                  :add="true"
                  :add_disable="notif_rule.id != null"
                  :Update="true"
                  @open="RecipientOpen"
                  @rowselect="RowRecipientSelect"
                  @delete="krecip++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <taskform :users="user_list"> </taskform>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-form lazy-validation>
      <v-dialog
        v-model="isVisible"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span> Component </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  autocomplete="off"
                  outlined
                  dense
                  v-model="Component.code"
                  label="Code"
                  :rules="[(v) => !!v || 'Code required']"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="8" md="8">
                <v-text-field
                  autocomplete="off"
                  outlined
                  dense
                  v-model="Component.label"
                  label="Label"
                  :rules="[(v) => !!v || 'Label required']"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="groups_cmp"
                  v-model="Component.group_id"
                  item-value="id"
                  item-text="label"
                  label="Group"
                  :rules="[(v) => !!v || 'Group required']"
                  outlined
                  dense
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  :items="orders_list"
                  v-model="Component.csg_order"
                  label="Order"
                  outlined
                  dense
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  :items="orientations"
                  v-model="Component.orientation"
                  label="Orientation"
                  outlined
                  dense
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="ComponentSave"> Save </v-btn>
            <v-btn color="blue darken-1" @click="ComponentClose">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <welltypecmp
      :dialog="dialog"
      :wtc_title="twt_title"
      :list="twt_list"
      v-on:close-dialog="closeDialog"
      @save="saveDialog"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
      <barrierform
        :dialog="barrier_dailog"
        :barrier="barrier_json"
        :components_list="allcomponents"
        :element="barrier_group"
        @close="BarrierClose"
        :id="idKey"
        :key="kbar"
      ></barrierform>
      <referenceform
        :showform="!isTestClose"
        :modify="test.statut_id == 1"
        :item="test"
        @close="isTestClose = true"
        :formations_fields_fluids="formations_fields_fluids"
        :fluids_elements="fluids_elements"
        :wells="wells"
        :user_list="user_list"
        @add="Kfieldformationfluidref++"
        @change="Kfieldformationfluidref++"
        :field_id="Field.id"
        :formation_id="FieldFormation.formation_id"
        :fluid_id="FieldFormationFluid.fluid_id"
        :key="refkey"
      ></referenceform>
      <recipientform
        :item="recipient"
        :items="recipients"
        :rule="notif_rule"
        :users="user_list"
        :profiles="profiles"
        :showForm="!isRcptClose"
        :key="krcpt"
        @close="isRcptClose = true"
      >
      </recipientform>
    </template>
  </div>
</template>
<script>
var aujd = new Date();
let annee = aujd.getFullYear();

import DATA from "../graphql/Dictionnary/DATA.gql";

import CREATE_FLAG from "../graphql/Dictionnary/Mutations/CREATE_FLAG.gql";
import DELETE_FLAG from "../graphql/Dictionnary/Mutations/DELETE_FLAG.gql";
import UPDATE_FLAG from "../graphql/Dictionnary/Mutations/UPDATE_FLAG.gql";

import CREATE_BARRIER from "../graphql/Dictionnary/Mutations/Failures/CREATE_BARRIER.gql";
import DELETE_BARRIER from "../graphql/Dictionnary/Mutations/Failures/DELETE_BARRIER.gql";
import UPDATE_BARRIER from "../graphql/Dictionnary/Mutations/Failures/UPDATE_BARRIER.gql";

import CREATE_FAILURE from "../graphql/Dictionnary/Mutations/Failures/CREATE_FAILURE.gql";
import DELETE_FAILURE from "../graphql/Dictionnary/Mutations/Failures/DELETE_FAILURE.gql";
import UPDATE_FAILURE from "../graphql/Dictionnary/Mutations/Failures/UPDATE_FAILURE.gql";

import CREATE_FAILURE_CMP_TYPE from "../graphql/Dictionnary/Mutations/Failures/CREATE_FAILURE_CMP_TYPE.gql";
import DELETE_FAILURE_CMP_TYPE from "../graphql/Dictionnary/Mutations/Failures/DELETE_FAILURE_CMP_TYPE.gql";
import UPDATE_FAILURE_CMP_TYPE from "../graphql/Dictionnary/Mutations/Failures/UPDATE_FAILURE_CMP_TYPE.gql";

import FIELD_FORMATION_INSERT from "../graphql/Dictionnary/Mutations/FIELD_FORMATION_INSERT.gql";
import DELETE_FORMATION_FIELD from "../graphql/Dictionnary/Mutations/DELETE_FORMATION_FIELD.gql";

import CREATE_FORMATION_FIELD_FLUID from "../graphql/Dictionnary/Mutations/CREATE_FORMATION_FIELD_FLUID.gql";
import UPDATE_FORMATION_FIELD_FLUID from "../graphql/Dictionnary/Mutations/UPDATE_FORMATION_FIELD_FLUID.gql";
import DELETE_FORMATION_FIELD_FLUID from "../graphql/Dictionnary/Mutations/DELETE_FORMATION_FIELD_FLUID.gql";

import CREATE_COMPONENT_TYPE from "../graphql/Dictionnary/Mutations/CREATE_COMPONENT_TYPE.gql";
import DELETE_COMPONENT_TYPE from "../graphql/Dictionnary/Mutations/DELETE_COMPONENT_TYPE.gql";
import UPDATE_COMPONENT_TYPE from "../graphql/Dictionnary/Mutations/UPDATE_COMPONENT_TYPE.gql";

import CREATE_EVENT_KEY from "../graphql/Dictionnary/Mutations/CREATE_EVENT_KEY.gql";
import UPDATE_EVENT_KEY from "../graphql/Dictionnary/Mutations/UPDATE_EVENT_KEY.gql";
import DELETE_EVENT_KEY from "../graphql/Dictionnary/Mutations/DELETE_EVENT_KEY.gql";

import CREATE_JOURNAL from "../graphql/Dictionnary/CREATE_JOURNAL.gql";
import UPDATE_JOURNAL from "../graphql/Dictionnary/UPDATE_JOURNAL.gql";
import DELETE_JOURNAL from "../graphql/Dictionnary/DELETE_JOURNAL.gql";

import CREATE_COMPONENT from "../graphql/Dictionnary/Mutations/CREATE_COMPONENT.gql";
import DELETE_COMPONENT from "../graphql/Dictionnary/Mutations/DELETE_COMPONENT.gql";
import UPDATE_COMPONENT from "../graphql/Dictionnary/Mutations/UPDATE_COMPONENT.gql";

import CREATE_TUBULAR from "../graphql/Dictionnary/Mutations/CREATE_TUBULAR.gql";
import DELETE_TUBULAR from "../graphql/Dictionnary/Mutations/DELETE_TUBULAR.gql";
import UPDATE_TUBULAR from "../graphql/Dictionnary/Mutations/UPDATE_TUBULAR.gql";

import CREATE_ELEMENT from "../graphql/Dictionnary/Mutations/CREATE_ELEMENT.gql";
import DELETE_ELEMENT from "../graphql/Dictionnary/Mutations/DELETE_ELEMENT.gql";
import UPDATE_ELEMENT from "../graphql/Dictionnary/Mutations/UPDATE_ELEMENT.gql";

import CREATE_FLUID from "../graphql/Dictionnary/Mutations/CREATE_FLUID.gql";
import DELETE_FLUID from "../graphql/Dictionnary/Mutations/DELETE_FLUID.gql";
import UPDATE_FLUID from "../graphql/Dictionnary/Mutations/UPDATE_FLUID.gql";

import CREATE_FORMATION from "../graphql/Dictionnary/Mutations/CREATE_FORMATION.gql";
import DELETE_FORMATION from "../graphql/Dictionnary/Mutations/DELETE_FORMATION.gql";
import UPDATE_FORMATION from "../graphql/Dictionnary/Mutations/UPDATE_FORMATION.gql";

import CREATE_FIELD from "../graphql/Dictionnary/Mutations/CREATE_FIELD.gql";
import DELETE_FIELD from "../graphql/Dictionnary/Mutations/DELETE_FIELD.gql";
import UPDATE_FIELD from "../graphql/Dictionnary/Mutations/UPDATE_FIELD.gql";

import CREATE_WELLS_STATUS from "../graphql/Dictionnary/Mutations/CREATE_WELLS_STATUS.gql";
import DELETE_WELLS_STATUS from "../graphql/Dictionnary/Mutations/DELETE_WELLS_STATUS.gql";
import UPDATE_WELLS_STATUS from "../graphql/Dictionnary/Mutations/UPDATE_WELLS_STATUS.gql";

import CREATE_WELLS_TYPES from "../graphql/Dictionnary/Mutations/CREATE_WELLS_TYPES.gql";
import DELETE_WELLS_TYPES from "../graphql/Dictionnary/Mutations/DELETE_WELLS_TYPES.gql";
import UPDATE_WELLS_TYPES from "../graphql/Dictionnary/Mutations/UPDATE_WELLS_TYPES.gql";

import DELETE_LEVEL from "../graphql/Dictionnary/DELETE_LEVEL.gql";
import CREATE_LEVEL from "../graphql/Dictionnary/CREATE_LEVEL.gql";
import UPDATE_LEVEL from "../graphql/Dictionnary/UPDATE_LEVEL.gql";

import DELETE_DEFAULT from "../graphql/Dictionnary/DELETE_DEFAULT.gql";
import CREATE_DEFAULT from "../graphql/Dictionnary/CREATE_DEFAULT.gql";
import UPDATE_DEFAULT from "../graphql/Dictionnary/UPDATE_DEFAULT.gql";

import DELETE_TOOL from "../graphql/Dictionnary/DELETE_TOOL.gql";
import CREATE_TOOL from "../graphql/Dictionnary/CREATE_TOOL.gql";
import UPDATE_TOOL from "../graphql/Dictionnary/UPDATE_TOOL.gql";

import DELETE_TOOL_DETAIL from "../graphql/Dictionnary/DELETE_TOOL_DETAIL.gql";
import CREATE_TOOL_DETAIL from "../graphql/Dictionnary/CREATE_TOOL_DETAIL.gql";
import UPDATE_TOOL_DETAIL from "../graphql/Dictionnary/UPDATE_TOOL_DETAIL.gql";

import DELETE_UC from "../graphql/Dictionnary/DELETE_UC.gql";
import CREATE_UC from "../graphql/Dictionnary/CREATE_UC.gql";
import UPDATE_UC from "../graphql/Dictionnary/UPDATE_UC.gql";

import DELETE_UNIT from "../graphql/Dictionnary/DELETE_UNIT.gql";
import CREATE_UNIT from "../graphql/Dictionnary/CREATE_UNIT.gql";
import UPDATE_UNIT from "../graphql/Dictionnary/UPDATE_UNIT.gql";

import DELETE_COMPANY from "../graphql/Dictionnary/DELETE_COMPANY.gql";
import CREATE_COMPANY from "../graphql/Dictionnary/CREATE_COMPANY.gql";
import UPDATE_COMPANY from "../graphql/Dictionnary/UPDATE_COMPANY.gql";

import DELETE_RULE from "../graphql/Dictionnary/DELETE_NOTIF_RULE.gql";
import CREATE_RULE from "../graphql/Dictionnary/CREATE_NOTIF_RULE.gql";
import UPDATE_RULE from "../graphql/Dictionnary/UPDATE_NOTIF_RULE.gql";

import DELETE_RECIPIENT from "../graphql/Dictionnary/DELETE_NOTIF_RECEIPT.gql";
import CREATE_RECIPIENT from "../graphql/Dictionnary/CREATE_NOTIF_RECEIPT.gql";
import UPDATE_RECIPIENT from "../graphql/Dictionnary/UPDATE_NOTIF_RECEIPT.gql";

import CREATE_NOTIF from "../graphql/Dictionnary/CREATE_NOTIF_TYPE.gql";
import UPDATE_NOTIF from "../graphql/Dictionnary/UPDATE_NOTIF_TYPE.gql";

import UPDATE_DOCTYPE from "../graphql/Dictionnary/UPDATE_DOCTYPE.gql";

import UPDATE_OPTIONS from "../graphql/Dictionnary/UPDATE_OPTIONS.gql";

export default {
  components: {
    welltypecmp: () => import("../components/WellTypeCmp.vue"),
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    barrierform: () => import("../components/BarriersForm2.vue"),
    referenceform: () => import("../components/ReferenceForm.vue"),
    recipientform: () => import("../components/RuleRecipient.vue"),
    taskform: () => import("../components/TaskDictionnary.vue"),
  },
  data() {
    return {
      idKey: -1,
      barrier_group: "",
      isVisible: false,
      barrier_dailog: false,
      orientations: ["L", "R"],
      orders_list: ["1", "2", "3", "4", "5", "6"],
      state: "I",
      tabs: 1,
      edite: {},
      dialog: false,
      company_list: [],
      formations_fields_fluids: [],
      user_list: [],
      profiles: [],
      wells: [],
      fluids_elements: [],
      Company: {},
      wtc_title: "",
      twt_title: "",
      tool_list: [],
      recipients: [],
      notifs: [],
      notif_rules: [],
      notif: {},
      notif_rule: {},
      Tool: {},
      level_list: [],
      tool_detail_list: [],
      defaults_list: [],
      Tool_detail: {},
      isTestClose: true,
      tests_list: [],
      twt_list: [],
      twt_headers: [],
      test: {},
      wtc_list: [],
      groups_cmp: [],

      Barrier: {},
      barriers_list: [],
      barrier_json: [],
      Failure: {},
      failures_list: [],
      teststypes_list: [],
      testwelltype_alllist: [],
      Tests_Well_Type_list: [],
      allcomponents: [],
      WellTypeComponent: {},
      welltypecomponent_alllist: [],
      welltypecomponent_list: [],
      welltypecomponentheaders: [],

      components_type_list: [],
      Component_type: {},

      Fluid_element: {},
      recipient: {},
      tubtype_list: [],
      Tubular: {},
      tubulars_list: [],

      Component: {},
      components_list: [],

      Failure_Cmp_Type: {},
      failures_type_list: [],

      element: {},
      elements_list: [],
      Fluid: {},
      fluids_list: [],

      Field: {},
      fields: [],
      FieldFormation: {},
      FieldFormationFluid: {},
      FieldFormationFluidRef: {},
      fieldsformations_list: [],
      fieldsformations_fluids: [],
      fieldsformations_fluids_references: [],
      Formation: {},
      formations_list: [],
      WellStatus: {},
      wellsstatus: [],

      WellType: {},
      wellstypes: [],
      country_id: "13",
      open: [],
      opened: [],
      opened_save: [],
      active: [],
      active_save: [],
      items: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      unites: [
        { id: "1", text: "DA" },
        { id: "1000", text: "KDA" },
        { id: "1000000", text: "MDA" },
      ],
      selected_annee: annee,
      act_annee: annee,
      progress: false,
      Loading: false,
      tab: null,
      klist: 10000,
      refkey: 20000,

      flag: {},
      flags: [],
      kflag: 2000000,
      kevent: 2005600,
      kjournal: 2005700,
      knotif: 15470,
      krule: 15580,
      krecip: 16580,
      krcpt: 17580,
      isRcptClose: true,
      notif_headers: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Name",
          value: "name",
          slot: "href",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Name required"],
          type: "text",
          sm: 4,
          md: 4,
        },
        {
          text: "Default Title",
          value: "default_title",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Title required"],
          type: "text",
          sm: 4,
          md: 4,
        },
        {
          text: "Type",
          value: "notif_type",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Type required"],
          type: "select",
          select_list: ["info", "warning", "alert"],
          sm: 4,
          md: 4,
        },

        {
          text: "Default Message",
          value: "default_message",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Message required"],
          type: "text",
          sm: 12,
          md: 12,
        },
        {
          text: "Read Delay (days)",
          value: "auto_mark_read_delay",
          selected: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },

        {
          text: "Active",
          value: "active",
          selected: true,
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },
      ],
      notif_rules_headers: [
        {
          text: "notification_type_id",
          value: "notification_type_id",
          default: "",
          edit: true,
          hiden: true,
        },

        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          slot: "href",
          rules: [(v) => !!v || "label required"],
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Resend After (hours)",
          value: "resend_after_hours",
          selected: true,
          edit: true,
          type: "number",
          sm: 6,
          md: 6,
        },
        {
          text: "In App",
          value: "send_in_app",
          selected: true,
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },
        {
          text: "Email",
          value: "send_by_email",
          selected: true,
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },
        {
          text: "Whatsapp",
          value: "send_by_sms",
          selected: true,
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },
      ],
      recipient_headers: [
        {
          text: "notification_rule_id",
          value: "notification_rule_id",
          default: "",
          edit: true,
          hiden: true,
        },

        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Recipient",
          value: "recipient_name",
          selected: true,
          slot: "href",
        },
        {
          text: "Type",
          value: "recipient_type",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Type required"],
          type: "Number",
          sm: 6,
          md: 6,
        },
      ],
      eventheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Operation",
          value: "label",
          selected: true,
        },
      ],

      eventkeyheaders: [
        {
          text: "cat_id",
          value: "cat_id",
          default: "",
          edit: true,
          hiden: true,
        },

        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Word",
          value: "key_word",
          selected: true,
          edit: true,
          slot: "href",
          rules: [(v) => !!v || "Word required"],
          type: "text",
          sm: 6,
          md: 6,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      journalheaders: [
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          slot: "href",
          type: "text",
          sm: 8,
          md: 8,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
        {
          text: "Events",
          value: "event_count",
          selected: true,
        },
      ],
      flagheaders: [
        {
          text: "Description",
          value: "description",
          selected: true,
          edit: true,
          slot: "href",
          type: "text",
          sm: 12,
          md: 12,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      barrierheaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Chemin",
          value: "chemin",
          slot: "href",
          selected: true,
        },
      ],
      companyheaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Description required"],
          sm: 8,
          md: 8,
        },
        {
          text: "Phone",
          value: "phone",
          selected: true,
          edit: true,
          type: "text",
          sm: 8,
          md: 8,
        },
        {
          text: "Adress",
          value: "adresse",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],
      levelheaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label required"],
          sm: 6,
          md: 6,
        },
        {
          text: "Gravity",
          value: "gravity",
          selected: true,
          edit: true,
          type: "number",
          rules: [(v) => !!v || v >= 0 || "Gravity required"],
          sm: 2,
          md: 2,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      defaultheaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label required"],
          sm: 8,
          md: 8,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      toolheaders: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Description required"],
          sm: 8,
          md: 8,
        },
        {
          text: "Company",
          value: "company_id",
          item_text: "name",
          item_value: "id",
          edit: true,
          slot: "simple-select",
          type: "select",
          selected: true,
          select_list: [],
          show_list: [],
          rules: [(v) => !!v || "Company required"],
          sm: 8,
          md: 8,
        },

        {
          text: "Version",
          value: "version",
          select: true,
          edit: true,
          selected: true,
          type: "text",
          sm: 4,
          md: 4,
        },
      ],
      tool_detailheaders: [
        {
          text: "tool_id",
          value: "tool_id",
          default: "",
          edit: true,
          hiden: true,
        },

        {
          text: "Id",
          value: "id",
        },
        {
          text: "ML from",
          value: "ml_from",
          selected: true,
          edit: true,
          slot: "href",
          type: "number",
          rules: [(v) => !!v || v >= 0 || "ML From required"],
          sm: 2,
          md: 2,
        },
        {
          text: "ML To",
          value: "ml_to",
          edit: true,
          selected: true,
          type: "number",
          rules: [(v) => !!v || "ML TO required"],
          sm: 2,
          md: 2,
        },

        {
          text: "WFM",
          value: "wfm",
          edit: true,
          selected: true,
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "Level",
          value: "level_id",
          edit: true,
          slot: "simple-select",

          item_text: "label",
          item_value: "id",
          select_list: [],
          show_list: [],
          type: "select",
          selected: true,
          rules: [(v) => !!v || "Level required"],
          sm: 6,
          md: 6,
        },
        {
          text: "Comment",
          value: "comment",
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],
      failureheaders: [
        {
          text: "Code",
          value: "code",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Code   required"],
          sm: 2,
          md: 2,
        },
        {
          text: "Issue",
          value: "issue",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Issue   required"],
          sm: 10,
          md: 10,
        },
        {
          text: "Description",
          value: "discription",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },

        {
          text: "External",
          value: "isexternal",
          selected: true,
          type: "check-box",
          slot: "checkbox",
        },
        {
          text: "External",
          value: "external",
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },

        {
          text: "Id",
          value: "id",
        },
        {
          text: "Value",
          value: "value",
          selected: true,
          type: "text",
        },
        {
          text: "Value",
          value: "value",
          edit: true,
          type: "select",
          select_list: ["0", "50"],
          sm: 4,
          md: 4,
        },
      ],
      tubularheaders: [
        {
          text: "code",
          value: "code",
          selected: true,
          readonly: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Code   required"],
          sm: 4,
          md: 4,
        },
        {
          text: "Label",
          value: "type",
          selected: true,
          edit: true,
          type: "text",
          readonly: true,
          item_value: "id",
          rules: [(v) => !!v || "Label   required"],
          sm: 4,
          md: 4,
        },
        {
          text: "Annulus",
          readonly: true,
          value: "annulus",
          selected: true,
          edit: true,
          type: "text",
          sm: 4,
          md: 4,
        },
        {
          text: "Item",
          value: "item",
          selected: true,
          rules: [(v) => !!v || "Item   required"],
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],

      component_type_headers: [
        {
          text: "Type",
          value: "type_id",
        },
        {
          text: "Type",
          value: "type",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Type   required"],
          sm: 9,
          md: 9,
        },
        {
          text: "Alr",
          value: "alr",
          selected: true,
          edit: true,
          type: "number",
          sm: 3,
          md: 3,
        },
      ],
      failuretypeheaders: [
        {
          text: "Failure",
          value: "failure",
          selected: true,
          slot: "href",
        },
        {
          text: "Type",
          value: "cmp_type_id",
          edit: true,
          hiden: "true",
          default: "",
        },

        {
          text: "Failure",
          value: "failure_id",
          edit: true,
          type: "select",
          select_list: [],
          item_text: "issue",
          item_value: "id",
          rules: [(v) => !!v || "Failure   required"],
          sm: 12,
          md: 12,
        },
        {
          text: "Failure",
          value: "failure_id",
        },
        {
          text: "Id",
          value: "id",
        },
      ],
      componentheaders: [
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          rules: [(v) => !!v || "Label   required"],
          sm: 12,
          md: 12,
        },
        {
          text: "Type",
          value: "type_id",
          edit: true,
          hiden: "true",
          default: "",
        },
        {
          text: "Group",
          value: "grp",
          selected: true,
          type: "select",
        },
        {
          text: "Group",
          value: "group_id",
          edit: true,
          type: "autocomplete",
          select_list: [],
          item_text: "label",
          item_value: "id",
          rules: [(v) => !!v || "Group   required"],
          sm: 6,
          md: 6,
        },
        {
          text: "Orientation",
          value: "orientation",
          type: "select",
          select_list: ["L", "R"],
          edit: true,
          selected: true,
          sm: 4,
          md: 4,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          edit: true,
          type: "text",
          rules: [(v) => !!v || "Code   required"],
          sm: 3,
          md: 3,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
        {
          text: "Status",
          value: "statut",
          type: "select",
          select_list: ["active", "inactive"],
          selected: true,
          sm: 4,
          md: 4,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
      elementheaders: [
        {
          value: "fluid_id",
          edit: true,
          hiden: "true",
          default: "",
        },
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label   required"],
          sm: 12,
          md: 12,
        },
        {
          text: "Unit",
          value: "unit",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
      ],
      fluidheaders: [
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label   required"],
          sm: 12,
          md: 12,
        },
      ],

      docs_typesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },

        {
          text: "Document",
          value: "document",
          slot: "href",
          selected: true,
        },
        {
          text: "Prefix",
          value: "prefix",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep.Prefix",
          value: "sep_pre",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Nombre de chiffre",
          value: "nbr",
          selected: true,
          edit: true,
          type: "number",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep. Année",
          value: "sep_an",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },

        {
          text: "Sep. Mois",
          value: "sep_mois",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
      ],

      unitcategories: [],
      docs_types: [],
      events: [
        { id: "1", label: "Workover", color: "red" },
        { id: "2", label: "Drilling", color: "green" },
        { id: "3", label: "Rigless", color: "purple" },
      ],
      events_keys: [],
      event_keys: [],
      journals: [],
      event: {},
      journal: {},
      cor_default: {},
      uc: {},
      unit: {},
      formationheaders: [
        {
          text: "Code",
          value: "code",
          selected: true,
          edit: true,
          type: "text",

          rules: [(v) => !!v || "Code required"],
          sm: 6,
          md: 6,
        },
        {
          text: "Name",
          value: "formationname",
          selected: true,
          edit: true,
          slot: "href",
          type: "text",
          color: "color",
          sm: 12,
          md: 12,
        },
        {
          text: "PFF",
          value: "ispff",
          selected: true,
          type: "check-box",
          slot: "checkbox",
        },
        {
          text: "PFF",
          value: "pff",
          edit: true,
          type: "check-box",
          slot: "checkbox",
          sm: 4,
          md: 4,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      fieldheaders: [
        {
          text: "Code",
          value: "code",
          selected: true,
          edit: true,
          type: "text",

          rules: [(v) => !!v || "Code required"],
          sm: 6,
          md: 6,
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          slot: "href",
          rules: [(v) => !!v || "Description required"],
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },

        {
          text: "Parent",
          value: "fk_parent",
          item_text: "description",
          item_value: "id",
          slot: "simple-select",
          rules: [(v) => !!v || "Parent required"],
          selected: true,
          select_list: [],
          show_list: [],
          edit: true,
          type: "select",
          sm: 8,
          md: 8,
        },

        {
          text: "Formations",
          value: "formationcount",
          selected: true,
        },
        {
          text: "Wells",
          value: "wellcount",
          selected: true,
        },
        {
          text: "Childs",
          value: "childcount",
          selected: false,
        },
      ],
      fieldformationheaders: [
        {
          value: "field_id",
          edit: true,
          hiden: "true",
          default: "",
        },
        {
          text: "Order",
          value: "no",
          selected: true,
        },
        {
          text: "Formation name",
          value: "formation_id",
          edit: true,
          slot: "simple-select",
          type: "select",
          selected: true,
          select_list: [],
          show_list: [],
          item_text: "formationname",
          item_value: "id",
          multiple: true,
          rules: [(v) => !!v || "Element   required"],
          sm: 12,
          md: 12,
        },
        {
          text: "Fluids",
          value: "fluidcount",
          selected: true,
        },
        {
          text: "Wells",
          value: "wellcount",
          selected: false,
        },
      ],

      fieldformation_fluids_headers: [
        {
          value: "formation_field_id",
          edit: true,
          hiden: "true",
          default: "",
        },
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Fluid name",
          value: "fluid_id",
          edit: true,
          slot: "simple-select",
          type: "select",
          selected: true,
          select_list: [],
          show_list: [],
          item_text: "label",
          item_value: "id",
          rules: [(v) => !!v || "Fluid required"],
          sm: 12,
          md: 12,
        },
      ],
      fieldformation_fluids_refrences_headers: [
        {
          text: "Code",
          value: "code",
          enum: "CODE",
          slot: "href",
          selected: true,
        },
        {
          text: "Field",
          value: "field_label",
          enum: "FIELD_LABEL",
          selected: true,
        },
        {
          text: "Formation",
          value: "formation_label",
          enum: "FORMATION_LABEL",
          selected: true,
        },
        {
          text: "Fluid",
          value: "fluid_label",
          enum: "FLUID_LABEL",
          selected: true,
        },
        {
          text: "Well",
          value: "well_name",
          enum: "WELL_NAME",
          selected: true,
        },
        {
          text: "Status",
          value: "status",
          enum: "STATUS",
          selected: true,
        },
        {
          text: "Date",
          value: "test_date",
          enum: "TEST_DATE",
          slot: "date",
          selected: true,
        },
        {
          text: "Conducted by",
          value: "agent_name",
          enum: "AGENT_NAME",
          selected: true,
        },
      ],
      wtypesheaders: [
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label   required"],
          sm: 12,
          md: 12,
        },
        {
          text: "Abr",
          value: "abr",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "Abreviation required"],
          type: "text",
          sm: 3,
          md: 3,
        },

        {
          text: "Color",
          value: "color",
          color: "color",
          slot: "color",
          rules: [(v) => !!v || "Color required"],
          selected: true,
          type: "color",
          edit: true,
          sm: 4,
          md: 4,
        },
      ],
      wstatusheaders: [
        {
          text: "Label",
          value: "label",
          selected: true,
          edit: true,
          type: "text",
          slot: "href",
          rules: [(v) => !!v || "Label   required"],
          sm: 12,
          md: 12,
        },

        {
          text: "Cnt",
          value: "cnt",
          selected: true,
        },
      ],
      unitcatheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],

          edit: true,
          type: "text",
          sm: 2,
          md: 2,
        },
      ],

      unitsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Name",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Name required"],

          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Factor",
          value: "factor",
          rules: [(v) => !!v || "Factor required"],
          selected: true,
          edit: true,
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "Rounding",
          value: "rounding",
          selected: true,
          edit: true,
          default: 0,
          rules: [(v) => !v || "Rounding required"],
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "category_id",
          value: "category_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
        {
          text: "uom_type",
          value: "uom_type",
          default: "m",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      kcompany: 3500,
      klevel: 20100,
      kdefault: 20200,
      ktool: 20000,
      ktool_detail: 30000,
      Kcomponent_type: 1600,
      Ktubular: 1700,
      Kwelltypecmp: 8000,
      Kcomponent: 1800,
      Kelement: 1900,
      Kfluid: 2000,
      Kfluid_element: 2100,
      Kformation: 2700,
      Kfieldformation: 3800,
      Kfieldformationfluid: 4800,
      Kfieldformationfluidref: 4850,
      Kfield: 2800,
      Kwstatus: 2900,
      Kwtypes: 3700,
      kuc: 500,
      ku: 600,
      kdoctype: 1500,
      Kfailure: 3500,
      Kfailuretype: 4000,
      kbarrier: 60000,
      kbar: 90000,
      change: 0,
      change2: 0,
      options: {},
    };
  },
  mounted() {
    this.refresh();
  },
  watch: {
    itemchange: {
      handler() {
        this.change++;
      },
      deep: true,
    },
  },
  computed: {
    active_fields() {
      return this.fields;
    },
    toolsdetails() {
      let l = [];
      if (this.Tool.tooldetails) l = this.Tool.tooldetails;
      return l.length;
    },
    itemchange() {
      this.options.max_file_size;
      this.options.doc_top;
      this.options.doc_bottom;
      this.options.doc_left;
      this.options.doc_right;
      this.options.volume_id;
      this.options.pressure_id;
      this.options.weight_id;
      this.options.time_id;
      this.options.depth_id;
      this.options.devise;
      return Date.now();
    },
    volume_units() {
      let l = [];
      if (this.unitcategories) {
        let i = this.unitcategories.findIndex((elm) => elm.id == 5);
        if (i >= 0) l = this.unitcategories[i].units;
      }
      return l;
    },
    pressure_units() {
      let l = [];
      if (this.unitcategories) {
        let i = this.unitcategories.findIndex((elm) => elm.id == 7);
        if (i >= 0) l = this.unitcategories[i].units;
      }
      return l;
    },
    weight_units() {
      let l = [];
      if (this.unitcategories) {
        let i = this.unitcategories.findIndex((elm) => elm.id == 2);
        if (i >= 0) l = this.unitcategories[i].units;
      }
      return l;
    },
    depth_units() {
      let l = [];
      if (this.unitcategories) {
        let i = this.unitcategories.findIndex((elm) => elm.id == 4);
        if (i >= 0) l = this.unitcategories[i].units;
      }
      return l;
    },
    time_units() {
      let l = [];
      if (this.unitcategories) {
        let i = this.unitcategories.findIndex((elm) => elm.id == 3);
        if (i >= 0) l = this.unitcategories[i].units;
      }
      return l;
    },
    Qflagcreate() {
      return CREATE_FLAG;
    },
    Qflagupdate() {
      return UPDATE_FLAG;
    },
    Qflagdelete() {
      return DELETE_FLAG;
    },

    Qbarriercreate() {
      return CREATE_BARRIER;
    },
    Qbarrierupdate() {
      return UPDATE_BARRIER;
    },
    Qbarrierdelete() {
      return DELETE_BARRIER;
    },

    Qcompanycreate() {
      return CREATE_COMPANY;
    },
    Qcompanyupdate() {
      return UPDATE_COMPANY;
    },
    Qcompanydelete() {
      return DELETE_COMPANY;
    },

    Qtool_detailcreate() {
      return CREATE_TOOL_DETAIL;
    },
    Qtool_detailupdate() {
      return UPDATE_TOOL_DETAIL;
    },
    Qtool_detaildelete() {
      return DELETE_TOOL_DETAIL;
    },

    Qeventkeycreate() {
      return CREATE_EVENT_KEY;
    },
    Qeventkeyupdate() {
      return UPDATE_EVENT_KEY;
    },
    Qeventkeydelete() {
      return DELETE_EVENT_KEY;
    },

    Qjournalcreate() {
      return CREATE_JOURNAL;
    },
    Qjournalupdate() {
      return UPDATE_JOURNAL;
    },
    Qjournaldelete() {
      return DELETE_JOURNAL;
    },
    Qnotifcreate() {
      return CREATE_NOTIF;
    },
    Qnotifupdate() {
      return UPDATE_NOTIF;
    },
    Qrulecreate() {
      return CREATE_RULE;
    },
    Qruleupdate() {
      return UPDATE_RULE;
    },
    Qruledelete() {
      return DELETE_RULE;
    },
    Qrecipientcreate() {
      return CREATE_RECIPIENT;
    },
    Qrecipientupdate() {
      return UPDATE_RECIPIENT;
    },
    Qrecipientdelete() {
      return DELETE_RECIPIENT;
    },
    Qtoolcreate() {
      return CREATE_TOOL;
    },
    Qtoolupdate() {
      return UPDATE_TOOL;
    },
    Qtooldelete() {
      return DELETE_TOOL;
    },
    Qlevelcreate() {
      return CREATE_LEVEL;
    },
    Qlevelupdate() {
      return UPDATE_LEVEL;
    },
    Qleveldelete() {
      return DELETE_LEVEL;
    },
    Qdefaultcreate() {
      return CREATE_DEFAULT;
    },
    Qdefaultupdate() {
      return UPDATE_DEFAULT;
    },
    Qdefaultdelete() {
      return DELETE_DEFAULT;
    },
    Qfailure_cmp_typecreate() {
      return CREATE_FAILURE_CMP_TYPE;
    },

    Qfailure_cmp_typeupdate() {
      return UPDATE_FAILURE_CMP_TYPE;
    },

    Qfailure_cmp_typedelete() {
      return DELETE_FAILURE_CMP_TYPE;
    },
    Qfailurecreate() {
      return CREATE_FAILURE;
    },
    Qfailureupdate() {
      return UPDATE_FAILURE;
    },
    Qfailuredelete() {
      return DELETE_FAILURE;
    },
    Qfieldformationfluidcreate() {
      return CREATE_FORMATION_FIELD_FLUID;
    },
    Qfieldformationfluidupdate() {
      return UPDATE_FORMATION_FIELD_FLUID;
    },
    Qfieldformationfluiddelete() {
      return DELETE_FORMATION_FIELD_FLUID;
    },
    Qfieldformationdelete() {
      return DELETE_FORMATION_FIELD;
    },
    Qtubularcreate() {
      return CREATE_TUBULAR;
    },
    Qtubularupdate() {
      return UPDATE_TUBULAR;
    },
    Qtubulardelete() {
      return DELETE_TUBULAR;
    },

    Qcomponentcreate() {
      return CREATE_COMPONENT;
    },
    Qcomponentupdate() {
      return UPDATE_COMPONENT;
    },
    Qcomponentdelete() {
      return DELETE_COMPONENT;
    },
    Qcomponent_typecreate() {
      return CREATE_COMPONENT_TYPE;
    },
    Qcomponent_typeupdate() {
      return UPDATE_COMPONENT_TYPE;
    },
    Qcomponent_typedelete() {
      return DELETE_COMPONENT_TYPE;
    },

    Qelementcreate() {
      return CREATE_ELEMENT;
    },
    Qelementupdate() {
      return UPDATE_ELEMENT;
    },
    Qelementdelete() {
      return DELETE_ELEMENT;
    },
    Qfluidcreate() {
      return CREATE_FLUID;
    },
    Qfluidupdate() {
      return UPDATE_FLUID;
    },
    Qfluiddelete() {
      return DELETE_FLUID;
    },
    Qformationcreate() {
      return CREATE_FORMATION;
    },
    Qformationupdate() {
      return UPDATE_FORMATION;
    },
    Qformationdelete() {
      return DELETE_FORMATION;
    },
    Qfieldcreate() {
      return CREATE_FIELD;
    },
    Qwstatuscreate() {
      return CREATE_WELLS_STATUS;
    },
    Qwtypescreate() {
      return CREATE_WELLS_TYPES;
    },
    Quccreate() {
      return CREATE_UC;
    },
    Qucupdate() {
      return UPDATE_UC;
    },
    Qucdelete() {
      return DELETE_UC;
    },
    Qfielddelete() {
      return DELETE_FIELD;
    },
    Qwtypesdelete() {
      return DELETE_WELLS_TYPES;
    },
    Qwstatusdelete() {
      return DELETE_WELLS_STATUS;
    },
    Qdoctypeupdate() {
      return UPDATE_DOCTYPE;
    },
    Qunitcreate() {
      return CREATE_UNIT;
    },
    Qunitupdate() {
      return UPDATE_UNIT;
    },
    Qfieldupdate() {
      return UPDATE_FIELD;
    },
    Qwtypesupdate() {
      return UPDATE_WELLS_TYPES;
    },
    Qwstatusupdate() {
      return UPDATE_WELLS_STATUS;
    },
    Qunitdelete() {
      return DELETE_UNIT;
    },

    unit_list() {
      return this.uc && this.uc.units ? this.uc.units : [];
    },
  },
  methods: {
    TestOpen(item) {
      this.test = item;
      this.isTestClose = false;
      this.refkey++;
    },
    TestChange(item) {
      this.test = item;
      this.selitem = item;
    },

    ReferenceClose(item) {
      if (item) {
        this.Kfieldformationfluidref++;
      } else {
        this.isTestClose = true;
      }
    },
    BarrierClose(item) {
      if (item) {
        if (item.isNew) this.barriers_list.push(item);
        else {
          this.Barrier.element = item.element;
          this.Barrier.chemin = item.chemin;
        }
      }
      this.barrier_dailog = false;
    },
    RowBarrierSelect(item) {
      if (item.chemin)
        this.barrier_json = item.chemin.split(";").filter((e) => e != "");
      this.barrier_group = item.element;
      this.idKey = parseInt(item.id);
      this.kbar++;
      this.barrier_dailog = true;
      this.Barrier = item;
    },
    BarrierOpen() {
      this.idKey = -1;
      this.barrier = [];
      this.kbar++;
      this.barrier_dailog = true;
    },
    field_formation_delete() {
      this.fieldformationheaders[2].select_list = this.formations_list.filter(
        (i) =>
          !this.fieldsformations_list.map((j) => j.formation_id).includes(i.id)
      );
      this.Field.formationcount--;
    },
    field_formation_fluid_delete() {
      this.fieldformation_fluids_headers[2].select_list =
        this.fluids_list.filter(
          (i) =>
            !this.fieldsformations_fluids.map((j) => j.fluid_id).includes(i.id)
        );
      this.FieldFormation.fluidcount--;
    },
    field_formation_fluid_add() {
      this.fieldformation_fluids_headers[2].select_list =
        this.fluids_list.filter(
          (i) =>
            !this.fieldsformations_fluids.map((j) => j.fluid_id).includes(i.id)
        );
      this.FieldFormation.fluidcount++;
    },
    async field_formation_add(item) {
      let list = [];
      list = item.formation_id.split(",");
      let s = "";
      list.forEach((element) => {
        let i = this.formations_list.findIndex((elm) => elm.id == element);
        if (i >= 0) s = s + this.formations_list[i].formationname + ",";
      });
      let f = this.fields.find((elm) => elm.id == item.field_id).description;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Add formations (" + s + ") to field " + f + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        let v = {
          field_id: item.field_id,
          list: list,
        };
        let r = await this.$maj(FIELD_FORMATION_INSERT, v);

        if (r.ok) {
          this.Field.formationcount = this.Field.formationcount + list.length;
          let l = JSON.parse(r.data.InsertFieldFormation);
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            let i = l.findIndex((elm) => elm.formation_id == element);
            if (i >= 0) {
              this.Field.formations.push({
                id: l[i].id,
                formation_id: element,
                wellcount: 1,
                fluidcount: 0,
                fluids: [],
              });
            }
          }
        }
      }
    },
    RowCompanySelect(item) {
      this.Company = item;
    },
    RowTool_detailSelect(item) {
      this.Tool_detail = item;
    },
    RowLevelSelect(item) {
      this.level = item;
    },
    RowdDefaultSelect(item) {
      this.cor_default = item;
    },
    RowToolSelect(item) {
      this.Tool = item;
      this.tool_detail_list = item.tooldetails;
      this.tool_detailheaders[0].default = item.id;
      this.ktool_detail++;
    },
    WellTypeCmpOpen(item) {
      this.wtc_list = [];
      this.wtc_title = item[1];
      let row = 0;
      this.wellstypes.forEach((elm) => {
        const l = {};
        l.id = null;
        l.component_id = item.id;
        l.welltype_id = elm.id;
        l.welltype = elm.abr;
        l.required_label = null;
        l.required = null;
        let index = this.welltypecomponent_list.findIndex(
          (y) => y.welltype_id == elm.id && y.component_id == item.id
        );
        if (index >= 0) {
          l.id = this.welltypecomponent_list[index].id;
          l.required = this.welltypecomponent_list[index].required;
          if (this.welltypecomponent_list[index].required == 1)
            l.required_label = "R";
          else if (this.welltypecomponent_list[index].required == 0)
            l.required_label = "O";
        }
        this.wtc_list[row] = l;
        row++;
      });
      this.dialog = true;
    },

    RowFailureSelect(item) {
      this.Failure = item;
    },
    RowFlagSelect(item) {
      this.flag = item;
    },
    keyAdd(item) {
      this.events_keys.push(item);
    },
    keyChange(item) {
      let i = this.events_keys.findIndex((elm) => elm.id == item.id);
      if (i >= 0) this.events_keys[i] = item;
    },
    RowNotifSelect(item) {
      this.notif = item;
      this.notif_rules = item.rules;
      this.notif_rules_headers[0].default = item.id;
      this.notif_rule = {};
      this.recipients = [];
      this.krule++;
      this.krecip++;
    },
    RowRecipientSelect(item) {
      this.recipient = item;
    },
    RecipientOpen(item) {
      this.recipient = item;
      this.isRcptClose = false;
      this.krcpt++;
    },
    RowRuletSelect(item) {
      this.notif_rule = item;
      console.log(this.notif_rule);
      this.recipients = item.recipients;
      this.krecip++;
      this.recipient_headers[0].default = item.id;
    },
    RowEventSelect(item) {
      this.event = item;
      this.event_keys = this.events_keys.filter((elm) => elm.cat_id == item.id);
      this.kevent++;
      this.eventkeyheaders[0].default = item.id;
    },
    RowJournaltSelect(item) {
      this.journal = item;
    },
    RowFormationSelect(item) {
      this.Formation = item;
    },
    RowFormationChange(item) {
      if (item.pff == 1) item.ispff = true;
      else item.ispff = false;
      this.Kformation++;
    },
    RowFailureChange(item) {
      if (item.external == 1) item.isexternal = true;
      else item.isexternal = false;
      // this.Failure.value = parseInt(item.value);
      this.Kfailure++;
    },
    RowTubularSelect(item) {
      this.Tubular = item;
    },

    RowComponent_Type_Select(item) {
      this.Component_type = item;
      this.components_list = item.components;
      this.failures_type_list = item.failures;
      this.componentheaders[3].select_list = this.grp_list;
      this.componentheaders[1].default = item.id;
      this.failuretypeheaders[1].default = item.id;
      this.failuretypeheaders[2].select_list = this.failures_list.filter(
        (i) => !this.failures_type_list.map((j) => j.failure_id).includes(i.id)
      );
      this.Kcomponent++;
      this.Kfailuretype++;
    },
    RowWTCSelect() {
      //  this.wtc_title = item[1];
    },

    RowComponentSelect(item) {
      this.Component = item;
      this.component_type_headers[0].default = item.id;
      this.componentheaders[3].select_list = this.groups_cmp;
    },
    ComponentOpen(item) {
      this.Component = item;
      this.isVisible = true;
    },
    ComponentAdd() {
      this.Component = {};
      this.isVisible = true;
    },
    ComponentClose() {
      this.isVisible = false;
    },
    ComponentSave() {
      let v = {
        id: this.Component.id,
        code: this.Component.code,
        label: this.Component.label,
        type_id: this.Component.type_id,
        compartment: this.Component.compartment,
        orientation: this.Component.orientation,
        csg_order: this.Component.csg_order,
      };
      this.$apollo
        .query({
          query: UPDATE_COMPONENT,
          variables: {
            component: v,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          console.log(data);
          this.isVisible = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    RowFailure_cmp_typeSelect(item) {
      this.failuretypeheaders[2].select_list = this.failures_list.filter(
        (i) => !this.failures_type_list.map((j) => j.failure_id).includes(i.id)
      );
      this.Failure_Cmp_Type = item;
    },
    RowFailure_cmp_typeAdd(item) {
      let elm = this.failures_list.findIndex(
        (element) => element.id === item.failure_id
      );
      item.failure = this.failures_list[elm].issue;
      this.Failure_Cmp_Type = item;
      this.failuretypeheaders[2].select_list = this.failures_list.filter(
        (i) => !this.failures_type_list.map((j) => j.failure_id).includes(i.id)
      );
    },
    RowFailure_cmp_typeChange(item) {
      let elm = this.failures_list.findIndex(
        (element) => element.id === item.failure_id
      );
      item.failure = this.failures_list[elm].issue;
      this.failuretypeheaders[2].select_list = this.failures_list.filter(
        (i) => !this.failures_type_list.map((j) => j.failure_id).includes(i.id)
      );

      //this.Kfailuretype++;
    },

    RowElementSelect(item) {
      this.Element = item;
    },
    RowFluid_ElementSelect(item) {
      this.Fluid_element = item;
    },

    RowFluidSelect(item) {
      this.Fluid = item;
      this.elements_list = item.elements;
      this.elementheaders[0].default = item.id;
      this.Kelement++;
    },

    RowFieldSelect(item) {
      this.Field = item;

      this.fieldformationheaders[0].default = item.id;

      this.fieldsformations_list = item.formations;

      this.fieldformationheaders[2].select_list = this.formations_list.filter(
        (i) =>
          !this.fieldsformations_list.map((j) => j.formation_id).includes(i.id)
      );
      this.Kfieldformation++;
      this.fieldsformations_fluids_references = [];
      this.Kfieldformationfluidref++;
    },

    RowFieldFormationSelect(item) {
      this.FieldFormation = item;
      this.fieldsformations_fluids_references = [];
      this.fieldformation_fluids_headers[0].default = item.id;
      this.fieldsformations_fluids = item.fluids;
      this.fieldformation_fluids_headers[2].select_list =
        this.fluids_list.filter(
          (i) =>
            !this.fieldsformations_fluids.map((j) => j.fluid_id).includes(i.id)
        );
      this.Kfieldformationfluid++;
      this.Kfieldformationfluidref++;
    },
    RowFieldFormationFluidSelect(item) {
      this.FieldFormationFluid = item;

      this.fieldsformations_fluids_references = item.refrences.filter(
        (elm) => (elm.fluid_id = item.fluid_id)
      );

      this.Kfieldformationfluidref++;
    },
    RowFieldFormationFluidRefSelect(item) {
      this.FieldFormationFluidRef = item;
    },
    RowWTypesSelect(item) {
      this.WellType = item;
    },
    RowWStatusSelect(item) {
      this.WellStatus = item;
    },
    save_options() {
      this.progress = true;
      let v = {
        options: {
          id: this.options.id,
          max_file_size: this.options.max_file_size,
          doc_top: this.options.doc_top,
          doc_bottom: this.options.doc_bottom,
          doc_left: this.options.doc_left,
          doc_right: this.options.doc_right,
          pressure_id: this.options.pressure_id,
          volume_id: this.options.volume_id,
          weight_id: this.options.weight_id,
          time_id: this.options.time_id,
          depth_id: this.options.depth_id,
          devise: this.options.devise,
          write_uid: parseInt(this.$store.state.me.id),
        },
      };
      this.$maj(UPDATE_OPTIONS, v);
      this.progress = false;
      this.change = 1;
    },
    gettestsWT() {
      //tests_list: [],
      // twt_list: [],
      this.twt_headers = [];
      this.twt_headers.push({
        text: "Id",
        value: "id",
        type: "text",
      });
      this.twt_headers.push({
        selected: true,
        text: "Test",
        value: "testtype",
        type: "text",
      });
      //let count = 2;
      this.wellstypes.forEach((element) => {
        this.twt_headers.push({
          selected: true,
          text: element.abr,
          value: element.id,
        });
        // count++;
      });
      this.twt_headers.push({
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        valeur: 0,
        sortable: false,
      });
      let row = 0;
      this.testwelltype_alllist = [];
      this.teststypes_list.forEach((elm) => {
        const l = {};
        l["id"] = elm.id;
        l["testtype"] = elm.description;

        this.Tests_Well_Type_list.filter(
          (i) => i.testtype_id == elm.id
        ).forEach((elm2) => {
          if (!l[elm2.welltype_id]) {
            l[elm2.welltype_id] = elm2.frequency;
            // l[elm2.welltype_id] = elm2.frequency;
          }
        });
        this.testwelltype_alllist[row] = l;
        row++;
      });
    },
    TestWellTypeOpen(item) {
      this.twt_list = [];
      this.twt_title = item.testtype;
      let row = 0;
      this.wellstypes.forEach((elm) => {
        const l = {};
        l.id = null;
        l.testtype_id = item.id;
        l.welltype_id = elm.id;
        l.welltype = elm.abr;
        l.frequency = null;
        l.graceperiode = null;
        let index = this.Tests_Well_Type_list.findIndex(
          (y) => y.welltype_id == elm.id && y.testtype_id == item.id
        );
        if (index >= 0) {
          l.id = this.Tests_Well_Type_list[index].id;
          l.frequency = this.Tests_Well_Type_list[index].frequency;
          l.graceperiode = this.Tests_Well_Type_list[index].graceperiode;
        }
        this.twt_list[row] = l;
        row++;
      });
      this.dialog = true;
    },
    getwellsTC() {
      this.gettestsWT();
      this.welltypecomponentheaders = [];
      this.welltypecomponentheaders.push({
        text: "Id",
        value: "id",
        type: "text",
      });
      this.welltypecomponentheaders.push({
        selected: true,
        text: "Code",
        value: "code",
        type: "text",
      });
      this.welltypecomponentheaders.push({
        selected: true,
        text: "Component",
        value: "label",
        type: "text",
      });

      let count = 2;
      this.wellstypes.forEach((element) => {
        this.welltypecomponentheaders.push({
          selected: true,
          text: element.abr,
          value: count,
          tag: element.id,
        });
        count++;
      });
      this.welltypecomponentheaders.push({
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        valeur: 0,
        sortable: false,
      });
      let row = 0;
      this.welltypecomponent_alllist = [];
      this.allcomponents.forEach((elm) => {
        const l = {};
        l["id"] = elm.id;
        l["label"] = elm.label;
        l["code"] = elm.code;
        this.welltypecomponent_list
          .filter((i) => i.component_id == elm.id)
          .forEach((elm2) => {
            let index = this.welltypecomponentheaders.findIndex(
              (y) => y.tag == elm2.welltype_id
            );
            if (elm2.required == "1") l[index] = "R";
            else if (elm2.required == "0") l[index] = "O";
          });
        this.welltypecomponent_alllist[row] = l;
        row++;
      });
    },
    async refresh() {
      this.loading = true;
      let r = await this.$requette(DATA);
      if (r) {
        this.flags = r.data.flag_list;
        this.events_keys = r.data.eventskeys;
        this.notifs = r.data.notificationTypes;
        this.journals = r.data.journals;
        this.unitcategories = r.data.unitcategories;
        this.docs_types = r.data.documents;
        this.options = r.data.options[0];
        this.wellstypes = r.data.wellstypes;
        this.wellsstatus = r.data.wellsstatus;
        this.tubulars_list = r.data.tubulars_list;
        this.allcomponents = r.data.components_list;
        this.fields = r.data.fields_list;
        this.fieldheaders[2].show_list = this.fields;
        this.fieldheaders[2].select_list = this.fields.filter(
          (elm) => elm.wellcount == 0
        );
        this.formations_list = r.data.formations_list;
        this.fieldformationheaders[2].show_list = this.formations_list;
        this.barriers_list = r.data.wmf_list;
        this.welltypecomponent_list = r.data.wellstypes_components;
        this.fluids_list = r.data.fluids_list;
        this.fieldformation_fluids_headers[2].show_list = this.fluids_list;
        this.components_type_list = r.data.components_type_list;
        this.groups_cmp = r.data.groups_cmp;
        this.tool_list = r.data.tool_list;

        this.defaults_list = r.data.defaults_list;

        this.company_list = r.data.company_list;
        this.teststypes_list = r.data.teststypes_list;
        this.failures_list = r.data.failures_list;
        this.Tests_Well_Type_list = r.data.Tests_Well_Type_list;
        this.formations_fields_fluids = r.data.formations_fields_fluids;
        this.fluids_elements = r.data.elements_list;
        this.user_list = r.data.users;
        this.profiles = r.data.profiles;
        this.wells = r.data.wells_list;
        this.getwellsTC();
        this.failuretypeheaders[2].select_list = this.failures_list;
        this.level_list = r.data.level_list;

        this.tool_detailheaders[5].select_list = this.level_list;
        this.tool_detailheaders[5].show_list = this.level_list;
        this.toolheaders[2].select_list = this.company_list;
        this.toolheaders[2].show_list = this.company_list;
        this.klevel++;
        this.kdefault++;
        this.ku++;
        this.Kcomponent_type++;
        this.kdoctype++;
        this.Kwtypes++;
        this.Kwstatus++;
        this.Kfield++;
        this.Kfieldformation++;
        this.Ktubular++;
        this.Kcomponent++;
        this.Kelement++;
        this.Kfluid++;
        this.Kwelltypecmp++;
        this.Kfailure++;
        this.kcompany++;
        this.kbarrier++;
        this.kflag++;
        this.kevent++;
        this.kjournal++;
        this.knotif++;
      }
      this.loading = false;
    },

    OpenUnitCatForm(item) {
      if (!item.units) item.units = [];
      this.uc = item;
      this.unitsheaders[4].default = this.uc.id;
      this.ku++;
    },
    FingerOpen() {},
    OpenUnitForm(item) {
      this.unit = item;
    },
    closeDialog() {
      this.dialog = false;
    },

    saveDialog() {
      this.refresh();
      this.getwellsTC();

      this.dialog = false;
    },
  },
};
</script>
<style>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
</style>
